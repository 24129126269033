// @ts-nocheck
import React from 'react';
import { useIntl, injectIntl } from 'gatsby-plugin-intl';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import 'animate.css/animate.min.css';

import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import Title from '../../components/Title';
import heroImage from '../../images/calgary-background.jpg';
import Footer from '../../components/Footer';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Heading = styled.h2`
  color: #074166;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 2px;
  margin: 1em 0em 1em 0em;
`;

const ParagraphContainer = styled.div`
  max-width: 100%;
  margin: 1em 1em 0em 1em;

  @media (min-width: 992px) {
    width: 95%;
    margin: 0em auto;
  }
`;

const Callout = styled.a`
  &&.btn {
    margin: 0em auto 1em auto;
    text-transform: uppercase;
    border-radius: 0px;
    padding: 0.7em 1em;
    align-items: center;
    display: flex;
    animation-delay: ${(props) => `${props.delay}s`};
  }
  &&.btn-primary {
    background: #185475;
    border-color: #185475;
  }
  &&.btn-secondary {
    background: #e9e9e9;
    border-color: #e9e9e9;
    color: black;
  }
  && svg {
    float: right;
    margin-left: 0.5em;
  }
  @media (min-width: 992px) {
    &&.btn-primary {
      margin: 0em 0em 1em auto;
    }
    &&.btn-secondary {
      // margin: 0em auto 1em 0em;
      margin: auto;
    }
  }
`;

const CalgaryPage = () => {
  const intl = useIntl();
  return (
    <Layout>
      <SEO title="Calgary" />
      <Wrapper className="container-fluid">
        <Title
          imageUrl={heroImage}
          position="center"
          text={intl.formatMessage({
            id: 'locations.canada.calgary.title',
          })}
        />
        <Heading>
          {intl.formatMessage({
            id: 'locations.canada.calgary.welcome_text',
          })}
        </Heading>
        <ParagraphContainer>
          <p>
            Calgary, a cosmopolitan Alberta city with numerous skyscrapers, owes
            its rapid growth to its status as the centre of Canada’s oil industry.
            However, it’s still steeped in the western culture that earned it the
            nickname “Cowtown,” and hosts the Calgary Stampede, a massive July
            rodeo and festival that grew out of the farming exhibitions once
            presented here. About a million people visit the show every year and
            it is billed as “The Largest Outdoor Show in the World”.
          </p>
          <p>
            Recently voted as one the most liveable cities in the world, Calgary
            is recognized for its high quality of life and flourishing economy,
            with booming industries including technology, film and television,
            aerospace, tourism and much more.
          </p>
        </ParagraphContainer>
        <Row>
          {/* <Col lg={6} className="d-flex">
            <Callout href="/apply" className="btn btn-primary">
              Apply now <i className="fas fa-chevron-right" />
            </Callout>
          </Col> */}
          <Col lg={12} className="d-flex">
            <Callout href="/contact-us" className="btn btn-secondary">
              {intl.formatMessage({ id: 'locations.contact_text' })}{' '}
              <i className="fas fa-chevron-right" />
            </Callout>
          </Col>
        </Row>
        <Footer />
      </Wrapper>
    </Layout>
  );
};

export default injectIntl(CalgaryPage);
